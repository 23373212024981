import React, { useState } from "react";
import './ContactUs.css';
import { FaMapMarkerAlt, FaEnvelope, FaPhoneAlt } from 'react-icons/fa';

// Assuming you're using axios for sending the request
// import axios from 'axios'; 


function ContactUs() {
    const [formData, setFormData] = useState({
        name: '',
        email: '',
        subject: '',
        phone: '',
        message: '',
    });


    const [statusMessage, setStatusMessage] = useState('');
    const [isSuccess, setIsSuccess] = useState(null);


    const handleChange = (e) => {
        setFormData({
            ...formData,
            [e.target.name]: e.target.value,
        })
    }

    const handleSubmit = async (e) => {
        e.preventDefault();

        try {
            const response = await fetch('http://thestudycafe.in/send', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(formData),
            })

            const data = await response.json();
            if (response.ok) {
                setIsSuccess(true);
                setStatusMessage('Message sent successfully!');

                // Clear the form fields after successful submission
                setFormData({
                    name: '',
                    email: '',
                    subject: '',
                    phone: '',
                    message: '',
                });
            }
            else {
                setIsSuccess(false);
                setStatusMessage(`Error: ${data.message}`);
            }
        }

        catch (error) {
            console.error('Error:', error);
            setIsSuccess(false);
            setStatusMessage('Failed to send message.');
        }

        // Clear the message after 3 seconds
        setTimeout(() => {
            setStatusMessage('');
        }, 3000);  // 3 seconds delay
    }

    return (
        <section className="contact-section" id='contact'>
            <div className="contact-container">
                {/* Contact Form */}
                <div className="contact-form">
                    <h2>Contact Us For More Info!</h2>
                    <form onSubmit={handleSubmit}>
                        <div className="form-row">
                            <input type="text" placeholder="Name" name='name' value={formData.name} onChange={handleChange} required />
                            <input type="email" placeholder="Email" name='email' value={formData.email} onChange={handleChange} required />
                        </div>
                        <div className="form-row">
                            <input type="text" placeholder="Subject" name='subject' value={formData.subject} onChange={handleChange} required />
                            <input type="tel" placeholder="Phone No" name='phone' value={formData.phone} onChange={handleChange} required />
                        </div>
                        <textarea placeholder="Message" name='message' value={formData.message} onChange={handleChange} required ></textarea>
                        <button type="submit" className="submit-btn">Enquire Now</button>

                        {statusMessage && (
                            <p className={`status-message ${isSuccess ? 'success' : 'error'}`}>
                                {statusMessage}
                            </p>
                        )}


                    </form>
                </div>

                {/* Contact Details */}
                <div className="contact-details">
                    <div className="detail-item">
                        <FaMapMarkerAlt className="contact-icon" />
                        <div>
                            <h4>Location</h4>
                            <p>B-74 Ground Floor, Sector-2, Near Sector 15 Metro Station</p>
                            <p>Noida-201301, G.B. Nagar (U.P.)</p>
                        </div>
                    </div>
                    <div className="detail-item">
                        <FaEnvelope className="contact-icon" />
                        <div>
                            <h4>Email Us</h4>
                            <p>thestudycafenoida@gmail.com</p>
                            <p>admin@thestudycafe.in</p>
                        </div>
                    </div>
                    <div className="detail-item">
                        <FaPhoneAlt className="contact-icon" />
                        <div>
                            <h4>Phone No</h4>
                            <p>9555-684-442</p>
                            <p>9555-684-443</p>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
}

export default ContactUs;
