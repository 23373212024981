import React from "react";
import './Aboutus.css';
import about1 from '../../images/about-1.jpeg'
import about2 from '../../images/about-2.jpeg'

function Aboutus() {
    return (
        <section className="about-us-section" id='about'>
            <div className="about-us-container" >
                <div className="about-us-text">
                    {/* <h1><span className="highlight">We Provide</span> Library Space Solution</h1> */}
                    <small>About Us</small>
                    <h1>We Provide Library Space Solution</h1>
                    <h2>for all your study needs</h2>
                    <p>A library is the delivery room for the birth of ideas, a place where history comes to life. The Study Cafe is a Noida-based library facility designed to take you one step closer to success. 
                    Our key features include comfortable seating, excellent lighting, a peaceful environment, 
                    cooperative staff, and a fast internet connection. We also offer a cafeteria for recharging 
                    during breaks, ensuring that you stay energized and focused. Open 24/7 and conveniently located 
                    near Noida Sector 15 Metro Station, The Study Cafe provides everything you need to increase 
                    productivity and reach your academic goals.</p>
                </div>
                <div className="about-us-images">
                    <div className="about-us-image">
                        <img src={about1} alt="Library Environment" />
                    </div>
                    <div className="about-us-image">
                        <img src={about2} alt="Study Environment" />
                    </div>
                </div>
            </div>
        </section>
    );
}

export default Aboutus;