import React, { useState } from "react";
import './Membership.css';

function Membership() {
    const [activeTab, setActiveTab] = useState('Time Slots');

    const tabData = {
        'Time Slots': [
            { time: '1 Day (12 Hours)', price: '200' },
            { time: '7 Days', price: '700' },
            { time: '3 Hours', price: '750 (7:00 AM & 8:00 AM)' },
            { time: '3 Hours After 4 PM', price: '650' },
            { time: '3 Hours After 6 PM ', price: '600' },
            { time: '4 Hours After 6 PM ', price: '700' },
            { time: '5 Hours After 6 PM ', price: '800' },
            { time: '6 Hours After 6 PM ', price: '900' },
            { time: '6 Hours After 4 PM ', price: '1100' },
            { time: '8 Hours After 4 PM ', price: '1250' },
            { time: 'Locker Charges ', price: '150 Per Month' },
        ],
        'Night Shift (After 6 PM)': [
            { time: '8 Hours', price: '1100' },
            { time: '12 Hours', price: '1350' }
        ],
        'Weekend Plan': [
            { time: 'Saturday & Sunday', price: '700 Per Month' },
            { time: '3 Hours Evening (After 4 PM + Weekend)', price: '1200 Per Month' },
            { time: '6 Hours Evening (After 4 PM + Weekend)', price: '1400 Per Month' },
        ],

        'General Monthly Plans': [
            { time: '6 Hours ( Before 8 AM )', price: '1200', quarterly: '3300' },
            { time: '8 Hours ( Before 8 AM & After 4 PM)', price: '1350', quarterly: '3700' },
            { time: '8 Hours ( Your Choice)', price: '1500', quarterly: '4100' },
            { time: '12 Hours', price: '1700', quarterly: '4700' },
            { time: '15 Hours', price: '1850', quarterly: '5000' },
            { time: '24 Hours', price: '2000', quarterly: '5300' },
            { time: '12 Hours Reserved Seat', price: '2250', quarterly: '6100' },
            { time: '24 Hours Reserved Seat', price: '2450', quarterly: '6600' },
        ],
    };

    const renderTable = () => {
        const data = tabData[activeTab];
        return (
            <table className="membership-table">
                <thead>
                    <tr>
                        <th>Plan</th>
                        <th>Price</th>
                        {data[0].quarterly && <th>Quarterly</th>}
                    </tr>
                </thead>
                <tbody>
                    {data.map((row, index) => (
                        <tr key={index} className={index % 2 === 0 ? 'even' : 'odd'}>
                            <td>{row.time}</td>
                            <td>{row.price}</td>
                            {row.quarterly && <td>{row.quarterly}</td>}
                        </tr>
                    ))}
                </tbody>
            </table>
        );
    };

    return (
        <section className="membership-section" id='charges'>
            <div className="membership-container">
                <h2 className="membership-title">Flexible Membership Study Plans</h2>
                <p className="membership-description">Discover flexible membership plans tailored to your study goals. Whether you are preparing for exams or just looking for a quiet space to focus, we offer affordable packages designed with your needs in mind. Choose the time slots that work best for your schedule, and make the most out of your learning experience. Your success starts here!</p>

                <div className="tab-buttons">
                    {Object.keys(tabData).map((tab, index) => (
                        <button
                            key={index}
                            className={`tab-button ${activeTab === tab ? 'active' : ''}`}
                            onClick={() => setActiveTab(tab)}
                        >
                            {tab}
                        </button>
                    ))}
                </div>

                <div className="table-container">
                    {renderTable()}
                </div>
            </div>
        </section>
    );
}

export default Membership;
