import React from "react";
import Navbar from "./components/Navbar/Navbar";
import HeroBanner from "./components/HeroBanner/HeroBanner";
import StudentAttractionCards from "./components/StudentAttractionCards/StudentAttractionCards";
import Aboutus from "./components/AboutUs/Aboutus";
import Membership from "./components/Membership/Membership";
import Facilities from "./components/Facilities/Facilities";
import WhoCanJoin from "./components/WhoCanJoin/WhoCanJoin";
import Reviews from "./components/Reviews/Reviews";
import Guidelines from "./components/Guidelines/Guidelines";
import ImageGallery from "./components/ImageGallery/ImageGallery";
import ContactUs from "./components/ContactUs/ContactUs";
import Footer from "./components/Footer/Footer";




function App() {
  return (
    <div >
      <Navbar />
      <HeroBanner />
      <StudentAttractionCards />
      <Aboutus />
      <Membership />
      <Facilities />
      <WhoCanJoin /> 
      <Reviews />
      <Guidelines />
      <ImageGallery />
      <ContactUs />
      <Footer />


    </div>
  );
}

export default App;
