import React from "react";
import { FaFacebookF, FaInstagram, FaLinkedinIn } from 'react-icons/fa';
import { IoLogoWhatsapp } from "react-icons/io";
import './Footer.css';

import logo from '../../images/logo-thestudycafe.png';


function Footer() {
    const currentYear = new Date().getFullYear(); // Get current year dynamically
    return (
        <footer className="footer">
            <div className="footer-container">

                {/* First Part: Logo and Brand Description */}
                <div className="footer-section footer-logo">
                    <img src={logo} alt="The Study Cafe Logo" className="footer-logo-img" />
                    <h2>The Study Cafe Library</h2>
                    <p>
                        A modern library providing students with a conducive environment for study and growth. Join us to experience learning in comfort.
                    </p>
                </div>

                {/* Second Part: Useful Links */}
                <div className="footer-section footer-links">
                    <h3>Useful Links</h3>
                    <ul>
                        <li><a href="#banner">Home</a></li>
                        <li><a href="#about">About</a></li>
                        <li><a href="#charges">Charges</a></li>
                        <li><a href="#facilities">Facilities</a></li>
                        <li><a href="#whocanjoin">Who can join</a></li>

                    </ul>
                </div>

                {/* Third Part: Additional Useful Links */}
                <div className="footer-section footer-links">
                    <h3>More Links</h3>
                    <ul>
                        <li><a href="#reviews">Reviews</a></li>
                        <li><a href="#guidelines">Guidelines</a></li>
                        <li><a href="#gallery">Gallery</a></li>
                        <li><a href="#contact">Contact Us</a></li>
                    </ul>
                </div>

                {/* Fourth Part: Follow Us */}
                <div className="footer-section footer-social">
                    <h3>Follow Us</h3>
                    <div className="social-icons">
                        <a href="https://www.facebook.com/studycafelibrary/" title='Facebook' target="_blank" rel="noopener noreferrer"><FaFacebookF /></a>

                        <a href="https://api.whatsapp.com/send/?phone=919555684443&text&type=phone_number&app_absent=0" title='WhatsApp' target="_blank" rel="noopener noreferrer"><IoLogoWhatsapp /></a>

                        <a href="https://www.instagram.com/thestudycafenoida" title='Instagram' target="_blank" rel="noopener noreferrer"><FaInstagram /></a>

                        <a href="https://www.linkedin.com/company/the-study-cafe-library/" title='Linkedin' target="_blank" rel="noopener noreferrer"><FaLinkedinIn /></a>
                    </div>
                </div>
            </div>

            {/* Footer Bottom Bar */}
            <div className="footer-bar">
                <div className="footer-bar-container">
                    <p className="footer-left">© {currentYear} TheStudyCafe. All Rights Reserved.</p>
                    <p className="footer-right">Website Developed by <a href="#PervezAlam">Pervez Alam</a></p>
                </div>
            </div>
        </footer>
    );
}

export default Footer;
