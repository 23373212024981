import React from "react";
import './HeroBanner.css'
import { useTypewriter, Cursor } from "react-simple-typewriter";

// import bannerImage from '../../images/library-1.jpeg'
import bannerImage from '../../images/library-2.jpeg'
// import bannerImage from '../../images/library-3.jpeg'
// import bannerImage from '../../images/banner-1.jpeg'



function HeroBanner() {

    const [text] = useTypewriter({
        words: [
            'Your Success Starts Here',
            'Prepare. Focus. Achieve.',
            'Focus on Your Success',
            'Study Smarter, Succeed Sooner',
            'Find Your Focus Zone'
        ],
        loop: true,       // Makes the typing loop infinitely
        typeSpeed: 80,    // Speed of typing
        deleteSpeed: 30,  // Speed of deleting
        delaySpeed: 2000, // Delay between typing new words
    });

    return (
        <section className="hero-banner" id='banner'>
            <div className="content-wrapper" >
                {/* Left Side: Text Content */}
                <div className="content">
                    <h3>Welcome to:</h3>
                    <h1>The Study Cafe Library</h1>
                    <h2>
                        {/* <span>{text}</span> */}
                        {text}
                        <Cursor
                            cursorStyle='|'
                            cursorColor="#00FFFF"
                        />
                    </h2>
                    <p>Join a focused environment that empowers you to excel in your exams and achieve success beyond the classroom.</p>
                    <a href="#contact" className="hero-button">Contact Us</a>
                </div>

                {/* Right Side: Students Image */}
                <div className="hero-image">
                    <img src={bannerImage} alt="Students" />
                </div>
            </div>
        </section>
    )
}

export default HeroBanner;      
