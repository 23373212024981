import React from "react";
import { FaCheckCircle } from 'react-icons/fa'; // Importing the tick icon
import './Guidelines.css';

import rules from '../../images/rules.jpeg'

function Guidelines() {
    return (
        <section className="guidelines-section" id='guidelines'>
            <div className="guidelines-container">
                {/* Left Side - Rules */}
                <div className="guidelines-left">
                    <h2 className="guidelines-title">Library Guidelines & Timings</h2>
                    <ul className="guidelines-list">
                        <li><FaCheckCircle className="tick-icon" /> Respect your space, keep the table clean.</li>
                        <li><FaCheckCircle className="tick-icon" /> No food in the reading room, lets keep it tidy.</li>
                        <li><FaCheckCircle className="tick-icon" /> Dispose of waste properly, cleanliness matters.</li>
                        <li><FaCheckCircle className="tick-icon" /> Keep your phone on silent for uninterrupted study.</li>
                        <li><FaCheckCircle className="tick-icon" /> Avoid leaving personal items unattended.</li>
                        <li><FaCheckCircle className="tick-icon" /> Vacant seats for more than an hour may be reassigned.</li>
                    </ul>
                    <p className="library-timing">* The library is open 24/7 except for the last Sunday of every month.</p>
                </div>

                {/* Right Side - Image */}
                <div className="guidelines-right">
                    <img src={rules} alt="Library rules" className="guidelines-image" />
                </div>
            </div>
        </section>
    );
}

export default Guidelines;
