import React, { useState } from "react";
import './ImageGallery.css';


import gallery1 from '../../images/gallery-1.jpg';
import gallery2 from '../../images/gallery-2.jpg';
import gallery3 from '../../images/gallery-3.jpg';
import gallery4 from '../../images/gallery-4.jpg';
import gallery5 from '../../images/gallery-5.jpg';
import gallery6 from '../../images/gallery-6.jpg';


const galleryImages = [
    { src: gallery1, alt: 'Image 1' },
    { src: gallery2, alt: 'Image 2' },
    { src: gallery3, alt: 'Image 3' },
    { src: gallery4, alt: 'Image 4' },
    { src: gallery5, alt: 'Image 5' },
    { src: gallery6, alt: 'Image 6' }
];

function ImageGallery() {
    const [selectedImage, setSelectedImage] = useState(null);

    const openImage = (image) => {
        setSelectedImage(image);
    };

    const closeImage = () => {
        setSelectedImage(null);
    };

    const nextImage = () => {
        const currentIndex = galleryImages.findIndex(img => img.src === selectedImage.src);
        const nextIndex = (currentIndex + 1) % galleryImages.length;
        setSelectedImage(galleryImages[nextIndex]);
    };

    const prevImage = () => {
        const currentIndex = galleryImages.findIndex(img => img.src === selectedImage.src);
        const prevIndex = (currentIndex - 1 + galleryImages.length) % galleryImages.length;
        setSelectedImage(galleryImages[prevIndex]);
    };

    return (
        <section className="gallery-section" id='gallery'>
            <div className="gallery-container">
                <h2 className="gallery-title">Our Image Gallery</h2>
                <div className="gallery-grid">
                    {galleryImages.map((image, index) => (
                        <div key={index} className="gallery-item" onClick={() => openImage(image)}>
                            <img src={image.src} alt={image.alt} className="gallery-image" />
                        </div>
                    ))}
                </div>

                {/* Modal for full-screen view */}
                {selectedImage && (
                    <div className="image-modal">
                        <span className="close-button" onClick={closeImage}>&times;</span>
                        <img src={selectedImage.src} alt={selectedImage.alt} className="full-image" />
                        <button className="prev-button" onClick={prevImage}>&lt;</button>
                        <button className="next-button" onClick={nextImage}>&gt;</button>
                    </div>
                )}
            </div>
        </section>
    );
}

export default ImageGallery;
