import React from "react";
import { FaBook, FaCheckCircle, FaMoneyBillAlt, FaCalendarAlt, FaStar, FaGraduationCap } from 'react-icons/fa'; // Importing icons from react-icons
import './StudentAttractionCards.css';

// Updated card data with React icons
const cardData = [
    { icon: <FaBook />, title: 'Learn What You Love', text: 'Explore topics you are passionate about in a focused environment.' },
    { icon: <FaCheckCircle />, title: 'Easy to Enroll', text: 'Join the library with a simple and hassle-free enrollment process.' },
    { icon: <FaMoneyBillAlt />, title: 'Discount Pricing', text: 'Affordable pricing options available for students preparing for exams.' },
    { icon: <FaCalendarAlt />, title: 'Flexible Timings', text: 'Study at your own pace with flexible hours tailored to your needs.' },
    { icon: <FaStar />, title: 'Success-Oriented', text: 'Our space is designed to help you focus and achieve your goals.' },
    { icon: <FaGraduationCap />, title: 'Achieve Your Goals', text: 'Join a community of driven students and prepare for success.' }
];

function StudentAttractionCards() {
    return (
        <section className="attraction-cards-section">
            <div className="cards-wrapper">
                {cardData.map((card, index) => (
                    <div key={index} className="card">
                        <div className="icon">{card.icon}</div> {/* React icon component */}
                        <h3>{card.title}</h3>
                        <p>{card.text}</p>
                    </div>
                ))}
            </div>
        </section>
    );
}

export default StudentAttractionCards;
