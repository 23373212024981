import React, { useState } from 'react';
import './Reviews.css';

import user1 from '../../images/user-1.jpg';


function toTitleCase(name) {
    return name.toLowerCase().replace(/\b\w/g, char => char.toUpperCase());
}


function Reviews() {
    const reviews = [
        {
            name: 'Jha Kay Kay',
            review: 'One of the best Library in Noida. Very good atmosphere and services. The owner is a really nice guy. Safe and sound place for all students.',
            image: user1
        },
        {
            name: 'Rachit Arora',
            review: 'Great space to isolate and focus for hours on end without any disturbance. Seats are comfortable and desks, spacious enough to have a laptop open on the side while you take notes. Thoroughly enjoyed the experience!',
            image: user1
        },
        {
            name: 'CHHANNULAL PRAJAPATI',
            review: 'Best library in noida separate space for study. huge building. Neat and clean premises for study owner is very down to earth person. Big space for two wheeler parking must try...',
            image: user1
        },
        {
            name: 'Mithun GM',
            review: 'Nice atmosphere best library in noida and just 1 min walking distance from noida sector 15 metro station.',
            image: user1
        },
        {
            name: 'Aakash Saxena',
            review: 'If you are looking for serious long hours of study and no disturbance in your work , this place is for you !! Comfortable seats , wide table area for study, a locker facility and a small cafe to grab a quick snack.',
            image: user1
        },
        {
            name: 'S Singh',
            review: 'Awesome atmosphere for study.perfect ambience for students.all the stuff that requires are here.cozy chair and spacious seat to keep Ur books and laptops.special charging possible nt on every cabin . disciplined atmosphere.',
            image: user1
        },
    ];

    const [currentIndex, setCurrentIndex] = useState(0);

    const handlePrev = () => {
        setCurrentIndex((prevIndex) =>
            prevIndex === 0 ? reviews.length - 2 : prevIndex - 1
        );
    };

    const handleNext = () => {
        setCurrentIndex((prevIndex) =>
            prevIndex === reviews.length - 2 ? 0 : prevIndex + 1
        );
    };

    const getReviewsToDisplay = () => {
        const firstIndex = currentIndex;
        const secondIndex = (currentIndex + 1) % reviews.length; // Wrap around if we reach the end
        return [reviews[firstIndex], reviews[secondIndex]];
    };

    return (
        <section className="reviews-section" id='reviews'>
            <div className="container">
                <div className="reviews-header">
                    <div className="text-section">
                        <small>What They’re Saying</small>
                        <h2>Some Feedbacks <br /> from Our Customers</h2>
                    </div>
                    <div className="arrow-section">
                        <button onClick={handlePrev} className="arrow left-arrow">&#10094;</button>
                        <button onClick={handleNext} className="arrow right-arrow">&#10095;</button>
                    </div>
                </div>


                
                <div className="reviews-wrapper">
                    <div className="review-card">
                        {getReviewsToDisplay().map((review, index) => (
                            <div key={index} className="review">

                                {/* Opening quote */}
                                {/*  <div className="quote-icon">
                                    <span>&#8220;</span> 
                                </div> */}


                                {/* Review content */}
                                <p>{review.review}</p>

                                {/* Reviewer Info */}
                                <div className="reviewer-info">
                                    <img src={review.image} alt={review.name} />
                                    <div>
                                        <h3>{toTitleCase(review.name)}</h3>
                                    </div>
                                </div>

                                {/* Closing quote */}
                                {/* <div className="quote-icon">
                                    <span>&#8221;</span> 
                                </div> */}

                            </div>
                        ))}
                    </div>


                    
                </div>

            

            </div>
        </section>
    );
}

export default Reviews;

