import React from 'react';
import './WhoCanJoin.css';

import upsc from '../../images/UPSC-Aspirants.jpg'
import sscBanking from '../../images/SSC-Banking-Exams.jpeg'
import professionalCourse from '../../images/Professional-Courses.jpeg'
import medicalEng from '../../images/Medical-Engineering-Entrances.jpeg'
import higherEdu from '../../images/Higher-Education.jpeg'
import competitiveExams from '../../images/Competitive-Exams.jpeg'

const WhoCanJoin = () => {
    const cards = [
        { 
            heading: 'UPSC Aspirants', 
            description: 'Prepare in a calm and focused environment tailored for Civil Services exam preparation.', 
            image: upsc 
        },
        { 
            heading: 'SSC & Banking Exams', 
            description: 'Get ready for SSC, Banking, and other competitive exams with dedicated resources.', 
            image: sscBanking 
        },
        { 
            heading: 'Professional Courses', 
            description: 'Perfect for students enrolled in CA, CS, CMA, and other professional courses.', 
            image: professionalCourse 
        },
        { 
            heading: 'Medical & Engineering Entrances', 
            description: 'Ideal study space for NEET, JEE, and other entrance exam candidates.', 
            image: medicalEng 
        },
        { 
            heading: 'Higher Education', 
            description: 'Great for students pursuing Master’s, PhD, or other advanced education courses.', 
            image: higherEdu 
        },
        { 
            heading: 'Competitive Exams', 
            description: 'Designed for students preparing for various competitive exams across fields.', 
            image: competitiveExams 
        }
    ];

    return (
        <section className="who-can-join-section" id='whocanjoin'>
            <div className="container">
                <h2 className="section-heading">Who Can Join the Library?</h2>
                <div className="cards-grid">
                    {cards.map((card, index) => (
                        <div key={index} className="join-card">
                            <div className="card-image">
                                <img src={card.image} alt={card.heading} />
                            </div>
                            <div className="card-content">
                                <h3 className="card-heading">{card.heading}</h3>
                                <p className="card-description">{card.description}</p>
                            </div>
                        </div>
                    ))}
                </div>
                <p className="many-more-text">and many more...</p>
                <p className="many-more-description">
                    Students preparing for UPSC, SSC, Banking, Competitive Exams, Professional Courses, Accounting, Medical Courses, Higher Education, and many more can join and benefit from our library.
                </p>
            </div>
        </section>
    );
};

export default WhoCanJoin;
