import React from 'react';
import './Facilities.css';

import freeWiFi from '../../images/Free-WiFi.jpeg';
import locker from '../../images/Locker-Service.jpeg';
import cctv from '../../images/CCTV-Camera.jpeg';
import airConditioned from '../../images/Fully-Air-Conditioned.jpeg';
import reserveSeat from '../../images/Reserve-Seat Option.jpeg';
import newspaper from '../../images/Newspaper-Books.jpeg';
import water from '../../images/Hot-and-Cold-Water.jpeg';
import canteen from '../../images/Canteen-Facility.jpg';
import peacefulEnv from '../../images/Peaceful-Environment.jpg';

function Facilities() {
    const services = [
        { name: 'Free WiFi', image: freeWiFi },
        { name: 'Locker Service', image: locker },
        { name: 'CCTV Camera', image: cctv },
        { name: 'Fully Air-Conditioned', image: airConditioned },
        { name: 'Reserve Seat Option', image: reserveSeat },
        { name: 'Newspaper & Books', image: newspaper },
        { name: 'Hot & Cold Water', image: water },
        { name: 'Canteen Facility', image: canteen },
        { name: 'Peaceful Environment', image: peacefulEnv }
    ];

    return (
        <section className="facilities-section" id='facilities'>
            <div className="facilities-container">
                <h2 className="facilities-title">Facilities Available</h2>
                <p className="facilities-description">
                    Discover the Ultimate Study Experience: Where Comfort Meets Focus! 
                    Enjoy top-tier amenities that boost your productivity and help you excel in a peaceful, well-equipped environment.
                </p>
                <div className="facilities-grid">
                    {services.map((service, index) => (
                        <div key={index} className="facility-card">
                            <img src={service.image} alt={service.name} className="facility-image" />
                            <h3 className="facility-name">{service.name}</h3>
                        </div>
                    ))}
                </div>
            </div>
        </section>
    );
}

export default Facilities;
